import {Component, Input} from '@angular/core';
import {
  IE3ConsignmentHouseLevel,
  IE3ConsignmentProcess,
  IE3GoodsItem,
  IE3TransportEquipment
} from "@portbase/bezoekschip-service-typescriptmodels";
import {ConsignmentUtils} from "../../consignment.utils";
import {openEditModal} from "../../../../common/utils";
import {
  MasterConsignmentDetailsComponent,
  MasterConsignmentDetailsComponentData
} from "../master-consignment-details/master-consignment-details.component";
import {
  GoodsConsignmentDetailsComponent,
  GoodsConsignmentDetailsComponentData
} from "../goods-consignment-details/goods-consignment-details.component";
import {
  EquipmentConsignmentDetailsComponent,
  EquipmentConsignmentDetailsComponentData
} from "../equipment-consignment-details/equipment-consignment-details.component";
import {
  HouseConsignmentDetailsComponent,
  HouseConsignmentDetailsComponentData
} from "../house-consignment-details/house-consignment-details.component";

@Component({
  selector: 'app-consignment-details-alerts',
  templateUrl: './consignment-details-alerts.component.html',
  styleUrls: ['./consignment-details-alerts.component.scss']
})
export class ConsignmentDetailsAlertsComponent {
  consignmentProcess: IE3ConsignmentProcess;
  errors: ErrorWithPointers[];

  trackByIndex = (index: number) => index;

  @Input("consignmentProcess")
  set setConsignmentProcess(value: IE3ConsignmentProcess) {
    this.consignmentProcess = value;
    if (value) {
      this.errors = ConsignmentUtils.getMessagesEns(value, value.ensTaskStatus, value.status)
        .map(s => ({
          ...s,
          header: `${s.header} – Reported by European customs`,
          summary: `${s.summary} – Reported by European customs`
        }))
        .concat(ConsignmentUtils.getTemporaryStorageStatusMessage(value.status?.filingStatusTemporaryStorage)
          .map(s => ({
            ...s,
            header: `${s.header} – Reported by Dutch customs`,
            summary: `${s.summary} – Reported by Dutch customs`
          })));
    }
  }

  getPointersWithDescription = (error: ErrorWithPointers): ErrorPointer[] =>
    error.pointers?.filter(e => e.description);

  openAlertDetails = (error: ErrorWithPointers) => {
    const houseConsignment = error.pointers.find(p => p.type === "houseLevel")?.model as IE3ConsignmentHouseLevel;
    const goodsItem = error.pointers.find(p => p.type === "goodsItem")?.model as IE3GoodsItem;
    const equipment = error.pointers.find(p => p.type === "equipment")?.model as IE3TransportEquipment;

    if (goodsItem) {
      openEditModal(GoodsConsignmentDetailsComponent, <GoodsConsignmentDetailsComponentData> {
        consignmentProcessId: this.consignmentProcess.consignmentProcessId,
        cachedConsignmentProcess: this.consignmentProcess,
        houseConsignmentNumber: houseConsignment?.consignmentNumber,
        cachedHouseConsignment: houseConsignment,
        equipmentNumber: equipment?.containerIdentificationNumber,
        goodsItemNumber: goodsItem?.goodsItemNumber
      }, {
        warnOnClose: true,
        currentToStack: true
      });
    } else if (equipment) {
      openEditModal(EquipmentConsignmentDetailsComponent, <EquipmentConsignmentDetailsComponentData> {
        consignmentProcessId: this.consignmentProcess.consignmentProcessId,
        cachedConsignmentProcess: this.consignmentProcess,
        houseConsignmentNumber: houseConsignment?.consignmentNumber,
        equipmentNumber: equipment?.containerIdentificationNumber,
        goodsItem: goodsItem
      }, {
        warnOnClose: true,
        currentToStack: true
      });
    } else if (houseConsignment) {
      openEditModal(HouseConsignmentDetailsComponent, <HouseConsignmentDetailsComponentData> {
        consignmentProcessId: this.consignmentProcess.consignmentProcessId,
        cachedConsignmentProcess: this.consignmentProcess,
        consignmentNumber: houseConsignment.consignmentNumber
      }, {
        warnOnClose: true,
        currentToStack: true
      });
    } else {
      openEditModal(MasterConsignmentDetailsComponent, <MasterConsignmentDetailsComponentData>{
        consignmentProcessId: this.consignmentProcess.consignmentProcessId,
        cachedConsignmentProcess: this.consignmentProcess
      }, {
        warnOnClose: true,
        currentToStack: true
      });
    }
  }
}

export interface ErrorWithPointers {
  header: string;
  summary: string;
  pointers: ErrorPointer[];
}

export interface ErrorPointer {
  description: string;
  index?: number;
  identification?: string;
  screenPointer?: string;
  model?: any;
  type?: string;
}
