import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {lodash, removeItem} from "../../common/utils";
import {TransitDeclaration, TransitPhase} from '@portbase/bezoekschip-service-typescriptmodels';

@Component({
  selector: 'app-transit-filter',
  templateUrl: './transit-filter.component.html',
  styleUrls: ['./transit-filter.component.css']
})
export class TransitFilterComponent implements TransitFilter, OnInit {

  options: FilterableItems[] = [
    TransitPhase.undeclared, TransitPhase.releaseRequested, TransitPhase.insufficientGuarantee, TransitPhase.rejected,
    TransitPhase.releaseRejected, TransitPhase.declared, TransitPhase.sent, TransitPhase.cancellationRequested,
    TransitPhase.accepted, TransitPhase.inTransit, TransitPhase.proofOfArrivalRequested,
    TransitPhase.proofOfArrivalSent, TransitPhase.arrived, TransitPhase.discrepancies, TransitPhase.writtenOff,
    TransitPhase.cancelled, "mrnsent", "mrnrejected", "mrnaccepted"];
  selectedOptions: FilterableItems[] = [TransitPhase.undeclared, TransitPhase.declared, TransitPhase.sent,
    TransitPhase.cancellationRequested, TransitPhase.rejected, TransitPhase.releaseRequested,
    TransitPhase.insufficientGuarantee, TransitPhase.proofOfArrivalRequested, TransitPhase.discrepancies];
  deselectedOptions: FilterableItems[] = [];

  @Input() transitDeclarations: TransitDeclaration[];
  @Output() changed = new EventEmitter<TransitFilter>();

  ngOnInit(): void {
    const storedOptions: { selectedOptions, deselectedOptions }
      = JSON.parse(localStorage.getItem('transit-filter'));
    if (storedOptions) {
      this.selectedOptions = storedOptions.selectedOptions || [];
      this.deselectedOptions = storedOptions.deselectedOptions || [];
    }
    this.onChanged();
  }

  private onChanged() {
    this.changed.emit(this);
    localStorage.setItem('transit-filter', JSON.stringify({
      selectedOptions: this.selectedOptions,
      deselectedOptions: this.deselectedOptions
    }));
  }

  phaseDisplayName(option: FilterableItems) {
    switch (option) {
      case 'undeclared':
        return 'Draft';
      case 'declared':
        return 'Scheduled';
      case 'mrnsent':
        return 'MRN/MID Sent';
      case 'mrnaccepted':
        return 'MRN/MID Accepted';
      case 'mrnrejected':
        return 'MRN/MID Rejected';
      case 'proofOfArrivalRequested':
        return 'Proof Of Arrival Req.';
      case 'cancellationRequested':
        return 'Cancellation Req.';
    }
    return lodash.startCase(option);
  }

  count(option: FilterableItems): number {
    return this.transitDeclarations.filter(t => t.status.phase == option || this.checkCondition(option, t)).length;
  }

  isOn(option: FilterableItems): boolean {
    return this.selectedOptions.some(o => option === o);
  }

  isOff(option: FilterableItems): boolean {
    return this.deselectedOptions.some(o => option === o);
  }

  isToggled(option: FilterableItems): boolean {
    return this.isOn(option) || this.isOff(option);
  }

  toggleOn(option: FilterableItems) {
    removeItem(this.deselectedOptions, option);
    const existingOption = this.selectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.selectedOptions, option);
    } else {
      this.selectedOptions.push(option);
    }
    this.onChanged();
  }

  toggleOff(option: FilterableItems) {
    removeItem(this.selectedOptions, option);
    const existingOption = this.deselectedOptions.find(o => option === o);
    if (existingOption) {
      removeItem(this.deselectedOptions, option);
    } else {
      this.deselectedOptions.push(option);
    }
    this.onChanged();
  }

  reset(option: FilterableItems) {
    removeItem(this.selectedOptions, option);
    removeItem(this.deselectedOptions, option);
    this.onChanged();
  }

  resetAll() {
    this.selectedOptions = [];
    this.deselectedOptions = [];
    this.onChanged();
  }

  checkCondition = (option: FilterableItems, t: TransitDeclaration) => {
    switch (option) {
      case 'mrnsent':
        return this.mrnSent(t);
      case 'mrnaccepted':
        return this.mrnAccepted(t);
      case 'mrnrejected':
        return this.mrnRejected(t);
      default:
        return false;
    }
  };


  mrnSent = (t: TransitDeclaration) => (t.status.mrnSent || t.status.mrnDeclaration) && !t.status.mrnAccept && !t.status.mrnReject;

  mrnAccepted = (t: TransitDeclaration) => t.status.mrnAccept;

  mrnRejected = (t: TransitDeclaration) => t.status.mrnReject;
}

export interface TransitFilter {
  selectedOptions: FilterableItems[];
  deselectedOptions: FilterableItems[];
  checkCondition: (option: FilterableItems, t: TransitDeclaration) => {};
}

export type FilterableItems = TransitPhase | MrnToTerminal;

export type MrnToTerminal = "mrnsent" | "mrnaccepted" | "mrnrejected";
