<ng-container *ngIf="errors?.length>0">
<div *ngFor="let alert of errors.slice(0, 2)" [ngClass]="alert.pointers?.length ? 'cursor-pointer' : ''"
     (click)="alert.pointers?.length ? openAlertDetails(alert) : null">
  <app-status-alert type="danger" [closeable]="false" cssClass="mb-0">
    <div class="row gx-3">
      <div class="col-auto d-flex align-items-center">
        <i class="fal fa-times-circle text-danger-dark fs-2"></i>
      </div>
      <div class="col d-flex flex-column">
        <div class="fw-medium mb-1">
          {{alert.header}}
        </div>
        <div class="row gx-1">
          <ng-container *ngFor="let pointer of getPointersWithDescription(alert); trackBy: trackByIndex; let last = last">
            <div class="col-auto d-flex flex-row">
              <div>{{pointer.description}}</div>
              <i *ngIf="!last" class="fa fa-light fa-arrow-right ms-1"></i>
            </div>
          </ng-container>
          <ng-container *ngIf="alert.pointers?.length">
            <div class="col-auto d-flex flex-row text-decoration-underline text-danger-dark fw-medium">
              <span>Click here to view</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </app-status-alert>
</div>
</ng-container>
