import {ErrorPointer} from "./details/consignment-details-alerts/consignment-details-alerts.component";
import lodash from "lodash";
import {
  IE3CL707,
  IE3ConsignmentHouseLevel,
  IE3ConsignmentMasterLevel,
  IE3ConsignmentProcess,
  IE3Party
} from "@portbase/bezoekschip-service-typescriptmodels";
import {ConsignmentUtils} from "./consignment.utils";

export class ConsignmentEnsUtils {

  static parseErrorPointers = (consignment: IE3ConsignmentProcess, pointer: string) => {
    const pointers = pointer?.split("/") || [];
    const errorPointers: ErrorPointer[] = [];
    pointers.forEach(e => {
      this.replaceEnsPointer(consignment, e, errorPointers[errorPointers.length - 1]).forEach(e => errorPointers.push(e));
    });
    return errorPointers;
  }

  private static replaceEnsPointer = (consignment: IE3ConsignmentProcess, description: string, previousPointer: ErrorPointer): ErrorPointer[] => {
    if (consignment == null) {
      return [{
        description: description
      }]
    }
    const getIndex = () => {
      const matches = description.match(/\[(\d+)]/);
      return matches ? lodash.parseInt(matches[1]) : null;
    };
    switch (description) {
      case "consignmentMasterLevel": case "ENS filing": case "activeBorderTransportMeans": return [{
        description: `Master B/L ${consignment?.consignmentMasterLevel.consignmentNumber}`,
        identification: consignment?.consignmentMasterLevel.consignmentNumber,
        model: consignment?.consignmentMasterLevel,
        type: "masterLevel"
      }];
      case "actualDateAndTimeOfDeparture": return [{
        description: "Time of departure",
        screenPointer: "Time of departure",
        model: previousPointer.model,
        type: previousPointer.type
      }]
      case "estimatedDateAndTimeOfArrival": return [{
        description: "Estimated time of arrival",
        screenPointer: "Estimated time of arrival",
        model: previousPointer.model,
        type: previousPointer.type
      }]
      case "containerIdentificationNumber": return [{
        description: "Equipment number",
        screenPointer: "Equipment number",
        model: previousPointer.model,
        type: previousPointer.type
      }];
      case "consignor": return [{
        description: "Parties",
        screenPointer: "Parties",
        model: previousPointer.model,
        type: previousPointer.type
      }, {
        description: "Consignor",
        screenPointer: "Consignor",
        model: previousPointer.type === "masterLevel"
          ? (previousPointer.model as IE3ConsignmentMasterLevel).consignor
          : (previousPointer.model as IE3ConsignmentHouseLevel).consignor,
        type: "consignor"
      }];
      case "consignee": return [{
        description: "Parties",
        screenPointer: "Parties",
        model: previousPointer.model,
        type: previousPointer.type
      }, {
        description: "Consignee",
        screenPointer: "Consignee",
        model: previousPointer.type === "masterLevel"
          ? (previousPointer.model as IE3ConsignmentMasterLevel).consignee
          : (previousPointer.model as IE3ConsignmentHouseLevel).consignee,
        type: "consignee"
      }];
      case "seller": return [{
        description: "Parties",
        screenPointer: "Parties",
        model: previousPointer.model,
        type: previousPointer.type
      }, {
        description: "Seller",
        screenPointer: "Seller",
        model: (previousPointer.model as IE3ConsignmentHouseLevel).goodsShipment.seller,
        type: "seller"
      }];
      case "buyer": return [{
        description: "Parties",
        screenPointer: "Parties",
        model: previousPointer.model,
        type: previousPointer.type
      }, {
        description: "Buyer",
        screenPointer: "Buyer",
        model: (previousPointer.model as IE3ConsignmentHouseLevel).goodsShipment.buyer,
        type: "buyer"
      }];
      case "supplementaryDeclarant": return [{
        description: "Parties",
        screenPointer: "Parties",
        model: previousPointer.model,
        type: previousPointer.type
      }, {
        description: "Supplementary declarant",
        screenPointer: "Supplementary declarant",
        model: previousPointer.type === "masterLevel"
          ? (previousPointer.model as IE3ConsignmentMasterLevel).supplementaryDeclarant
          : (previousPointer.model as IE3ConsignmentHouseLevel).supplementaryDeclarant,
        type: "supplementaryDeclarant"
      }];
      case "commodityCode": return [{
        description: "HS or CN code",
        screenPointer: "HS or CN code",
        model: previousPointer.model,
        type: previousPointer.type
      }];
      case "identificationNumber": return [{
        description: "Identification number",
        screenPointer: "Identification number",
        model: previousPointer.model,
        type: previousPointer.type
      }]
      case "LRN": return [{
        description: "Please contact Portbase Customer Service",
        screenPointer: null
      }]
      case "identifier": case "goodsShipment": case "address": case "commodity": return [];
    }
    if (description.startsWith("IE3F")) {
      return [];
    }
    if (description.indexOf("consignmentHouseLevel") > -1 || description.indexOf("House Consignment") > -1) {
      const index = getIndex() || 0;
      const houseConsignment = consignment?.consignmentMasterLevel.consignmentsHouseLevel[index];
      return [{
        description: `House B/L ${houseConsignment.consignmentNumber}`,
        identification: houseConsignment.consignmentNumber,
        model: houseConsignment,
        type: "houseLevel"
      }]
    }
    if (description.indexOf("notifyParty") > -1) {
      const index = getIndex() || 0;
      const party = previousPointer.type === "masterLevel"
        ? consignment?.consignmentMasterLevel?.notifyParties[index]
        : (previousPointer.model as IE3ConsignmentHouseLevel).notifyParties[index];
      return [{
        description: "Parties",
        screenPointer: "Parties",
        model: previousPointer.model,
        type: previousPointer.type
      }, {
        description: `Notify party ${party.name}`,
        screenPointer: "Notify parties",
        model: party,
        type: "notifyParty"
      }]
    }
    if (description.indexOf("goodsItem") > -1 || description.indexOf("Goods Item") > -1) {
      const index = getIndex();
      const goodItem = previousPointer.type === "masterLevel"
        ? consignment?.consignmentMasterLevel?.goodsItems[index]
        : (previousPointer.model as IE3ConsignmentHouseLevel).goodsItems[index];
      return [{
        description: `Good ${ConsignmentUtils.goodsItemCode(goodItem.commodity)}`,
        index: index,
        identification: `${goodItem.goodsItemNumber}`,
        model: goodItem,
        type: "goodsItem"
      }]
    }
    if (description.indexOf("transportEquipment") > -1) {
      const index = getIndex();
      const equipment = Object.values(consignment?.consignmentMasterLevel.transportEquipmentMap)[index];
      return [{
        description: `Equipment ${equipment.containerIdentificationNumber}`,
        index: index,
        identification: equipment.containerIdentificationNumber,
        model: equipment,
        type: "equipment"
      }]
    }
    if (description.indexOf("communication") > -1) {
      const index = getIndex();
      const party = previousPointer.model as IE3Party;
      const communication = party.communications[index];
      return communication == null ? [] : [{
        description: communication.type === IE3CL707.TE ? 'Telephone' : 'Email address',
        model: communication,
        index: index,
        type: "communication",
        identification: communication.identifier,
        screenPointer: communication.type === IE3CL707.TE ? 'Telephone' : 'Email address'
      }]
    }
    if (description.indexOf("postCode") > -1) {
      const party = previousPointer.model as IE3Party;
      const text = description === "postCode" ? "Postal code" : description;
      return [{
        description: text,
        model: party,
        type: "postalCode",
        identification: party.identificationNumber,
        screenPointer: text
      }]
    }
    return [{
      description: description
    }];
  }
}
