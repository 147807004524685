import {Component, Input} from '@angular/core';
import {TransitDeclaration, TransitGoodsItem, TransitPhase} from "@portbase/bezoekschip-service-typescriptmodels";
import {lodash} from "../../common/utils";
import {PortvisitUtils} from "../../refdata/portvisit-utils";

@Component({
  selector: 'app-transit-summary',
  templateUrl: './transit-summary.component.html',
  styleUrls: ['./transit-summary.component.css']
})
export class TransitSummaryComponent {
  @Input() keepTooltipOpen: boolean;
  model: TransitDeclaration;
  refData = PortvisitUtils;

  packageCount = () => 0;
  weight = () => 0;
  phase = (): string => null;
  phaseType = (): 'danger' | 'warning' | 'info' | 'success' | 'secondary' | 'dark' | 'light' => null;

  @Input() set setModel(model: TransitDeclaration) {
    this.model = model;
    this.phase = () => {
      switch (model.status.phase) {
        case TransitPhase.declared:
          return 'scheduled';
        case TransitPhase.cancelled:
          return model.status.cancelInitiatedByCustoms ? 'cancelled by customs' : 'cancel accepted';
        case TransitPhase.undeclared:
          return 'draft';
      }
      return model.status.phase;
    };
    this.phaseType = () => {
      switch (model.status.phase) {
        case TransitPhase.discrepancies:
        case TransitPhase.rejected:
        case TransitPhase.releaseRejected:
          return 'danger';
        case TransitPhase.insufficientGuarantee:
        case TransitPhase.proofOfArrivalRequested:
          return 'warning';
        case TransitPhase.sent:
        case TransitPhase.releaseRequested:
        case TransitPhase.cancellationRequested:
          return 'info';
        case TransitPhase.accepted:
        case TransitPhase.inTransit:
        case TransitPhase.proofOfArrivalSent:
        case TransitPhase.arrived:
        case TransitPhase.writtenOff:
          return model.status.phase === 'accepted' && this.showModeOfTransportWarning() ? 'warning' : 'success';
        case TransitPhase.declared:
          return 'dark';
        case TransitPhase.undeclared:
          return 'light';
        case TransitPhase.cancelled:
          return 'secondary';
      }
    };
  }

  @Input() set item(item: TransitGoodsItem) {
    this.items = [item];
  }

  @Input() set items(items: TransitGoodsItem[]) {
    this.weight = () => lodash.sumBy(items, g => g.weight || 0);
    this.packageCount = () => lodash.sumBy(items, g => g.numberOfPackages);
  }

  showModeOfTransportWarning(): boolean {
    return this.model?.status.phase == "accepted" && this.model?.data.departureTransport?.modeOfTransport === "NOT_SPECIFIED";
  }

  hasMrnStatus() {
    return !!this.model.status.mrnSent || !!this.model.status.mrnAccept || !!this.model.status.mrnReject;
  }
}
