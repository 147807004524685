import {Component, Input, OnInit} from '@angular/core';
import {TransitDeclaration} from "@portbase/bezoekschip-service-typescriptmodels";
import {cloneObject, sendCommand} from "../../../common/utils";
import {AppContext} from "../../../app-context";
import {PortvisitUtils} from "../../../refdata/portvisit-utils";
import moment from 'moment';

@Component({
  selector: 'app-transit-declaration-admin',
  templateUrl: './transit-declaration-admin.component.html',
  styleUrls: ['./transit-declaration-admin.component.css']
})
export class TransitDeclarationAdminComponent implements OnInit {
  @Input() declaration: TransitDeclaration;
  adminCommand:any;
  adminCommands:any[];
  incomingMessages:any[];
  outgoingMessages:any[];
  refData = PortvisitUtils;

  ngOnInit(): void {
    this.adminCommands = this.getAdminCommands();
    this.incomingMessages = this.getIncomingMessages();
    this.outgoingMessages = this.getOutgoingMessages();
  }

  openMessages(processId: string) {
    PortvisitUtils.redirectToMessageManagement(processId);
  }

  getIncomingMessages() {
    let statusList = [];
    const status = this.declaration.status;
    if (status.accept) {
      statusList.push({name: "Accept", status: status.accept})
    }
    if (status.reject) {
      statusList.push({name: "Reject", status: status.reject})
    }
    if (status.releaseRejected) {
      statusList.push({name: "Release rejected", status: status.releaseRejected})
    }
    if (status.release) {
      statusList.push({name: "Release accepted", status: status.release})
    }
    if (status.insufficientGuarantee) {
      statusList.push({name: "Insufficient guarantee", status: status.insufficientGuarantee})
    }
    if (status.infoRequest) {
      statusList.push({name: "Additional information request", status: status.infoRequest})
    }
    if (status.cancellation) {
      statusList.push({name: "Cancellation registered", status: status.cancellation})
    }
    if (status.arrival) {
      statusList.push({name: "Arrived", status: status.arrival})
    }
    if (status.discrepancies) {
      statusList.push({name: "Discrepancies", status: status.discrepancies})
    }
    if (status.writeOff) {
      statusList.push({name: "Written off", status: status.writeOff})
    }
    return statusList.length === 0 ? null : statusList;
  }

  getOutgoingMessages() {
    let statusList = [];
    const status = this.declaration.status;
    if (status.sent) {
      statusList.push({name: "Declaration", status: status.sent})
    }
    if (status.releaseRequest) {
      statusList.push({name: "Release request", status: status.releaseRequest})
    }
    if (status.infoDeclared) {
      statusList.push({name: "Additional info declaration", status: status.infoDeclared})
    }
    if (status.cancellationRequest) {
      statusList.push({name: "Cancellation request", status: status.cancellationRequest})
    }
    if (status.mrnDeclaration) {
      statusList.push({name: "Mrn declared", status: status.sent, to: "Terminal"})
    }
    return statusList.length === 0 ? null : statusList;
  }

  selectAdminCommand(adminCommand){
    this.adminCommand = cloneObject(adminCommand);
  }

  sendAdminCommand() {
    let command = cloneObject(this.adminCommand);
    command.lrn = this.declaration.lrn;
    command.crn = this.declaration.crn;
    if(!!this.declaration.status.mrn) command.mrn = this.declaration.status.mrn;
    if(!!command.reason) command.reasons = [{reason: command.reason}];
    if(!!command.requestingOffice)command.requestingOffice = command.requestingOffice.unCode;
    command.name = null;

    sendCommand(this.adminCommand['@class'], command, () => {
      AppContext.registerSuccess('The admin command was sent successfully.');
    });
  }

  getAdminCommands() {
    let adminCommands = [];
    const status = this.declaration.status;
    if( status.cancellation || status.writeOff) return null;

    if (status.accept || status.reject){
      adminCommands.push({name: "Cancel transit", '@class': "com.portbase.bezoekschip.common.api.transit.CancelTransit",
          initiatedByCustoms: !status.cancellationRequest});
    }
    if (status.sent) {
      adminCommands.push({name: "Accept declaration", '@class': "com.portbase.bezoekschip.common.api.transit.AcceptTransit"})
      adminCommands.push({name: "Reject declaration", '@class': "com.portbase.bezoekschip.common.api.transit.RejectTransit", reason: "Rejected by Portbase"})
    }
    if (status.accept && !status.release && !status.releaseRejected) {
      adminCommands.push({
        name: "Release",
        '@class': "com.portbase.bezoekschip.common.api.transit.ReleaseTransit",
        inspectionStatus: {}
      })
      adminCommands.push({
        name: "Stop release",
        '@class': "com.portbase.bezoekschip.common.api.transit.StopTransitRelease"
      })
      if (!status.insufficientGuarantee) {
        adminCommands.push({
          name: "Register insufficient guarantee",
          '@class': "com.portbase.bezoekschip.common.api.transit.RegisterTransitInsufficientGuarantee"
        })
      }
    }
    if (status.mrn) {
      adminCommands.push({
        name: "Resend MRN to Terminal",
        '@class': "com.portbase.bezoekschip.common.api.transit.DeclareMrnToTerminal"
      })
    }
    if (status.mrnDeclaration && !status.mrnAccept && !status.mrnReject) {
      adminCommands.push({
        name: "Accept MRN as Terminal",
        '@class': "com.portbase.bezoekschip.common.api.transit.AcceptMrnToTerminal"
      })
      adminCommands.push({
        name: "Reject MRN as Terminal",
        '@class': "com.portbase.bezoekschip.common.api.transit.RejectMrnToTerminal"
      })
    }

    if (status.release && !status.arrival) {
      adminCommands.push({
        name: "Register arrival",
        '@class': "com.portbase.bezoekschip.common.api.transit.RegisterTransitArrival"
      })
      if (!status.infoRequest) {
        adminCommands.push({
          name: "Request proof of arrival",
          '@class': "com.portbase.bezoekschip.common.api.transit.RequestProofOfArrival"
        })
      }
    }

    if(status.release && !status.writeOff){
      adminCommands.push({name: "Write-off", '@class': "com.portbase.bezoekschip.common.api.transit.WriteOffTransit"})
    }

    if (!!this.declaration.crn && !status.writeOff) {
      adminCommands.push({
        name: "Refresh reference data",
        '@class': "com.portbase.bezoekschip.common.api.transit.TryRefreshTransitRefData"
      });
    }

    adminCommands.push({
      name: "Refresh summary",
      '@class': "com.portbase.bezoekschip.common.api.transit.RefreshTransitSummary"
    });

    return adminCommands.length === 0 ? null : adminCommands.map(c => {
      c.date = moment().format('YYYY-MM-DD') + "T00:00:00Z";
      return c;
    });
  }

}
